* {
  box-sizing: border-box;
  font-size: 14px;
  font-family: Roboto, Arial, Helvetica, sans-serif;
}

body,
header,
main {
  align-items: center;
  display: flex;
}

body {
  justify-content: center;
  flex-flow: column;
  margin: 0;
}

header,
main {
  max-width: 1440px;
  padding: 0.5em;
  width: 100%;
}

header {
  justify-content: space-between;
}

main {
  background-image: linear-gradient(
      to left,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.75),
      rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 1)
    ),
    url("../../images/truck.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  flex-flow: column;
}

h1 {
  font-size: 18px;
  font-weight: 400;
  margin: 0;
  padding-left: 0.3em;
  text-align: center;
}

h2 {
  font-size: 30px;
  font-weight: 400;
  margin: 0;
  padding-bottom: 0.5em;
  text-align: center;
}

section {
  align-items: flex-start;
  display: flex;
  flex-flow: column;
  padding: 1.5em;
  width: 100%;
}

p {
  color: #2b2b2b;
  line-height: 1.8;
  margin: 0 0 2em;
  max-width: 500px;
}

footer {
  display: flex;
  justify-content: center;
  padding: 0.5em 0 1em;
}

footer a {
  color: #414a53;
  padding: 0.5em;
  text-align: center;
  text-decoration: none;
}

footer a:visited {
  color: #414a53;
}

footer a:hover {
  color: #035ec7;
  text-decoration: underline;
}

button {
  background: #0b8843;
  border: 1px solid #d4d4d4;
  border-radius: 0.25em;
  color: #fff;
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  padding: 0.5em 0.7em;
}

button:hover {
  background: #0a793d;
}

.title {
  display: flex;
  align-items: center;
}

.link-button {
  border: 1px solid #d4d4d4;
  border-radius: 0.25em;
  cursor: pointer;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  text-decoration: none;
}

.link-button ~ .link-button {
  margin-top: 2em;
}

.default {
  background: #0b8843;
  color: #fff;
  font-size: 15px;
  padding: 0.5em 0.7em;
}

.default:hover {
  background: #0a793d;
}

.outline {
  border-color: #a0a0a0;
  color: #0b8843;
  font-size: 16px;
  padding: 0.8em 1.1em;
}

.outline:hover {
  border-color: #0a793d;
  background: rgb(10, 121, 61, 0.03);
  color: #0a793d;
}
